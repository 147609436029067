.nav-scroll {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    animation: scroll 0.5s ease-in;
    box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 193, 231, 0.631372549);
    backdrop-filter: blur(1rem);
    transition: var(--transition);
  }
  
  @keyframes scroll {
    0% {
      transform: translateY(-90px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  .navbar-brand h3 {
    font-family: var(--stylish-font);
    color: var(--white);
    font-size: 3.5rem !important;
  }
  
  .nav-item .nav-link {
    display: inline-block;
    margin-right: 2rem;
    color: var(--white);
    font-weight: light;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-size: 1.4rem;
    position: relative;
    padding-bottom: 1rem;
    transition: var(--transition);
  }
  
  .nav-item .nav-link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0.3rem;
    height: 0rem;
    border-radius: 10rem;
    background-color: var(--blue);
    transition: var(--transition);
  }
  
  .nav-item .nav-link:hover,
  .nav-item .nav-link:focus {
    color: var(--blue);
  }
  
  .nav-item .nav-link:hover::before {
    left: 0;
    width: 100%;
    height: 0.1rem;
  }
  
  .nav-item .nav-link.active {
    color: var(--blue) !important;
  }