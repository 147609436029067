@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

body{
  display: flex;
  flex-direction: column;
}


/* general styles */
h1{
  font-size: 3.5rem;
}

p{
  font-size: 1.5rem;
}

#title{
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 6rem;
  height: 7rem;
}

/* body styles */
#sectionA .text-image{
  width: 90%;
}

#sectionB .text-image{
  width: 100%;
  justify-content: space-between;
}

#sectionB p{
  font-size: 1rem;
  color: #626262;
}

.text-image{
  margin-top: 10rem;
  margin: auto;
  display: flex;
  align-items: center;
}

.title-desc{
  text-align: left;
  height: max-content;
  width: 40%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  -ms-flex-align: center;
}

.section{
  display: block;
  width: 100%;
  text-align: center;
  /* padding: 4rem 0; */
  /* height: min-content; */
  background: #EAEDF0;
  /* padding-bottom: 10vh; */
  overflow: hidden;
}


/* buttons and forms */
.button{
  background: #627E8B;
  color: #fff;
  width: max-content;
  border-radius: 1rem;
  transition: all 100ms ease;
}

.button:hover {
  color: #000;
  background: #fff;
  border: 2px solid;
  cursor: pointer;
}

.button:disabled {
  background-color: #e0e0e0;
}

.button:disabled:hover {
  color: #fff;
  border: 1px solid #000;
  background-color: #e0e0e0;
}

.linedTitle{
  padding-top: 2rem;
  border-bottom: 1px solid;
}

#sectionA{
  padding-bottom: 5rem;
}

#sectionB{
  display: flex;
  background: #fbfbfb;
}

#sectionB2{
  width: 90%;
  margin: auto;
  text-align: left;
  display: flex;
  margin: 0 3rem;
  
}

form{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 4rem;
  width: 60%;
}

input{
  font-size: 2rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

textarea{
  font-size: 2rem;
  height: 10rem;
}

.banner{
  width: 20%;
  background-color: #dedede;
  height: inherit;
  margin-right: 0;
  align-items: center;
  text-align: center;
}

.bannerContainer{
  border-top: 1px solid;
  border-bottom: 1px solid;
  width: 80%;
  height: 90%;
  margin: 5vh auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

#graph{
  /* height: 35rem; */
  width: 60%;
}

/* #bannerLogo{
  height: max-content;
  width: inherit;
  margin: 0 auto;
} */


#contact{
  width: 80%;
  margin: auto;
}

#phone{
  margin: 2rem 0;
  height: 80%;
}

/* @media screen and (max-width: 768px) {
  .section {
    padding: 10px;
    background-color: #e0e0e0;
  }
}


@media screen and (min-width: 1200px) {
  .section {
    padding: 30px;
    background-color: #d0d0d0;
  }
} */

/* Your existing CSS */

/* Add a media query for smaller screens */

@media screen and (max-height: 600px) or (min-width: 400px) {
  .section {
    height: auto; /* Change the height to auto for smaller screens */
    padding: 4rem 0; 
  }
  
  .text-image {
    flex-direction: column; /* Stack the images and text in column for smaller screens */
    margin-top: 5rem; /* Adjust the top margin */
  }

  .title-desc {
    width: 90%; /* Take more width for smaller screens */
    text-align: center; /* Center align text for smaller screens */
    margin: 2rem auto; /* Adjust margins for smaller screens */
  }

  #graph {
    height: auto; /* Adjust the height to auto for smaller screens */
    width: 90%; /* Adjust width if needed */
  }

  .sectionB {
    flex-direction: column; /* Change the layout to column for smaller screens */
  }


  #sectionB2{
      margin: 0;
      width: 100%;
  }

  #contact{
      margin: auto;
  }

  form {
    width: 100%; /* Take full width for smaller screens */
  }

  form > * {
      font-size: 2rem;
  }
}

@media screen and (max-width: 576px) {
  /* #sectionA{
      height: 100vh;
  } */

  #sectionB2{
      margin: 0;
      width: 100%;
  }


  h1{
      font-size: 2rem;
      text-align: center;
  }

  p{
      text-align: center;
  }
  .text-image{
      flex-direction: column;
      width: 100%;
      margin-top: 0;
      text-align: center;
  }
  #title{
      padding-bottom: 2rem;
  }
  .button{
      margin: auto;
  }
  #graph{
      display: none;
  }
  #phone{
      display: none;
  }

  form > *{
      font-size: 1.5rem;
  }

  form {
      width: inherit;
  }

  #sectionB{
      flex-direction: column;
  }

  .banner{
      margin-top: 2rem;
      width: 100%;
      height: 40vh;
  }

  .bannerContainer{
      height: 70%;

  }

  .linedTitle{
      text-align: center;
      margin-bottom: 2rem;
  }

  #bannerLogo{
      height: inherit;
      width: fit-content;
  }
  #bannerLogo{
      height: 60%;
      width: fit-content;
  }



  /* Specific styles for smaller screens */
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .section{
      height: 100%;
  }
  #graph{
      display: none;
  }

  #sectionB2{
      margin: 0;
      width: 100%;
  }


  h1{
      font-size: 2rem;
      text-align: center;
  }

  p{
      text-align: center;
  }
  .text-image{
      flex-direction: column;
      width: 100%;
      margin-top: 0;
      text-align: center;
  }
  #title{
      padding-bottom: 2rem;
  }
  .linedTitle{
      padding-top: 1rem;
  }
  .button{
      margin: auto;
  }
  #graph{
      display: none;
  }
  #phone{
      display: none;
  }

  form > *{
      font-size: 1.5rem;
  }

  form {
      width: inherit;
  }

  #sectionB{
      padding-top: 3rem;
      flex-direction: column;
  }

  .banner{
      margin-top: 2rem;
      width: 100%;
      height: 40vh;
  }

  .bannerContainer{
      height: 70%;
      gap: 0;
  }

  .linedTitle{
      text-align: center;
      margin-bottom: 2rem;
  }

  .contactDetails{
      font-size: 10rem;
  }

  #bannerLogo{
      height: 60%;
      width: fit-content;
  }
}  

/* For medium-sized screens (tablets) */
@media screen and (min-width: 605px) and (max-width: 992px) {

}

/* For larger screens (desktops) */
@media screen and (min-width: 993px) {
  /* Specific styles for larger screens */
}
