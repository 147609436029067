

.background-image-div {
    position: relative;
    z-index: 0;
}

.background-image-div::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../public/images/windFarm.png');
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    z-index: -1;
}



.qual-section {

    background: linear-gradient(to right, #EAEDF0 60%, #D9D9D9 20%);
}
